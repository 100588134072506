import React from 'react';
import classNames from 'classnames';
import Header from '@mom-web/components/src/header';
import Card from '@mom-web/components/src/card';
import entry from '../json/entry.json';
import Entries from '@mom-web/components/src/entries';
import Entry from '@mom-web/components/src/types/entry';
import Timeline from '@mom-web/components/src/timeline';
import History from '@mom-web/components/src/history';
import News from '@mom-web/components/src/news';
import Twitter from '@mom-web/components/src/twitter';
import Footer from '@mom-web/components/src/footer';
import Marks from '@mom-web/components/src/marks';
import Information from '@mom-web/components/src/information';
import history from '../json/history.json';
import photos from '../json/photo.json';
import news from '../json/news.json';
import Photos from "@mom-web/components/src/photos";

const logo = require('../assets/logo.svg');
const logoColor = require('../assets/logo_color.svg');
const datetime = require('../assets/datetime.svg');
const loadingImage = require('../assets/loading.jpg');

function TopApp() {
  return (
    <div id="TopApp">
      <section className="hero is-black has-background-fullscreen">
        <div className="hero-head">
          <Header
            logo={logo}
            datetime={datetime}
            additional={
              <li className="is-hidden-mobile">
                <a href="https://missofmiss.jp/" target="_blank">
                  <span className="icon is-small">
                    <i className="fas fa-external-link-alt" aria-hidden="true" />
                  </span>
                  <span>MISS OF MISS</span>
                </a>
              </li>
            }
          />
        </div>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-margin-top-4-desktop is-margin-top-3-touch is-margin-bottom-5 is-margin-bottom-3-touch is-margin-bottom-2-mobile">
              <img src={logo} alt={process.env.REACT_APP_WEBSITE_NAME} />
            </h1>
            <h2 className="subtitle is-margin-bottom-3">
              <img src={datetime} alt="2020年3月26日(火) マイナビBLITZ赤坂 開催決定!" />
            </h2>
          </div>
        </div>
      </section>
      {/*

        Award

      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">AWARD</h1>
            <h2 className="subtitle">受賞者一覧</h2>
          </div>
          {/* グランプリ */}
          <div className="is-margin-bottom-3">
            <h1 className="title has-text-link is-size-3 as-section-title">グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${6}`}
                entry={{
                  "id": 6,
                  "mysta": "",
                  "showroom": "mom20_mr_6",
                  "college": "桜美林大学",
                  "name": "一 光希",
                  "furigana": "はじめ こうき",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "リベラルアーツ学群",
                  "twitter": "mr5obirin_2019",
                  "instagram": "mr5obirin_2019",
                  "hobby": "tiktok YouTube 曲を聴く",
                  "skill": "ダンス　トリッキング",
                  "dream": "アーティスト",
                  "enthusiasm": "10/27の悔しさをバネにして、\n必ず頂点に立ちます。",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1999/10/24",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="is-margin-bottom-3">
            <h1 className="title is-size-5 as-section-title">準グランプリ</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${16}`}
                entry={{
                  "id": 16,
                  "mysta": "",
                  "showroom": "mom20_mr_16",
                  "college": "明治学院大学",
                  "name": "木立 瑠耶",
                  "furigana": "きだち るか",
                  "hometown": "神奈川県",
                  "grade": 3,
                  "department": "経済学部",
                  "twitter": "19mgumr_no5",
                  "instagram": "19mgumr_no5",
                  "hobby": "映画鑑賞、読書",
                  "skill": "バルーンアート、ハンドボール",
                  "dream": "かっこいい父親になること",
                  "enthusiasm": "明治学院の代表として精一杯頑張って皆の力を借りて優勝を目指します！！",
                  "circle": "",
                  "magazine": "メンズファッジ",
                  "birthday": "1998/12/14",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${23}`}
                entry={{
                  "id": 23,
                  "mysta": "",
                  "showroom": "mom20_mr_23",
                  "college": "明治学院大学",
                  "name": "落合 純平",
                  "furigana": "おちあい じゅんぺい",
                  "hometown": "栃木県",
                  "grade": 3,
                  "department": "法学部",
                  "twitter": "19MGUMr_No1",
                  "instagram": "jjjumpei",
                  "hobby": "テラスハウスを観ること",
                  "skill": "けん玉",
                  "dream": "人を魅了できる人間",
                  "enthusiasm": "100点ではなく100%で、感謝を忘れず頑張りたいと思います！応援よろしくお願いします！",
                  "circle": "けん玉サークル",
                  "magazine": "men's FUDGE",
                  "birthday": "1998/06/11",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="is-margin-bottom-3">
            <h1 className="title is-size-5 as-section-title">審査員特別賞</h1>
            <h2 className="subtitle" />
          </div>
          <div className="columns is-centered is-multiline is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${5}`}
                entry={{
                  "id": 5,
                  "mysta": "",
                  "showroom": "mom20_mr_5",
                  "college": "獨協大学",
                  "name": "内藤 智也",
                  "furigana": "ないとう ともや",
                  "hometown": "埼玉県",
                  "grade": 3,
                  "department": "外国語学部",
                  "twitter": "mdc19_mr_5",
                  "instagram": "mdc19_mr_5",
                  "hobby": "一人旅、スポーツ観戦、筋トレ、カメラ",
                  "skill": "ギター、筋肉ルーレット",
                  "dream": "世界で一番幸せな家庭を築く！",
                  "enthusiasm": "「自分らしさ」を忘れず最後まで頑張ります！応援よろしくお願い致します！",
                  "circle": "",
                  "magazine": "ラグビーマガジン、サッカーマガジン",
                  "birthday": "1998/10/21",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${14}`}
                entry={{
                  "id": 14,
                  "mysta": "",
                  "showroom": "mom20_mr_14",
                  "twitter": "niino0311",
                  "college": "日本大学",
                  "name": "新納 侃",
                  "furigana": "にいの なおと",
                  "hometown": "千葉県",
                  "grade": 3,
                  "department": "文理学部",
                  "instagram": "niino0311",
                  "hobby": "読書,旅行",
                  "skill": "剣道",
                  "dream": "youtuber,俳優",
                  "enthusiasm": "グランプリ勝ち取りたいです。",
                  "circle": "",
                  "magazine": "MTLR",
                  "birthday": "",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${30}`}
                entry={{
                  "id": 30,
                  "mysta": "",
                  "showroom": "mom20_mr_30",
                  "college": "法政大学",
                  "name": "吉田 翔馬",
                  "furigana": "よしだ しょうま",
                  "hometown": "千葉県",
                  "grade": 3,
                  "department": "人間環境学部",
                  "twitter": "mrhosei_no3_19",
                  "instagram": "mrhosei_no3_19",
                  "hobby": "ダンス、ドライブ、妹と遊ぶこと、友達とボケ合うこと",
                  "skill": "ダンス、野球、書道、トロンボーン、腹話術、ダジャレ",
                  "dream": "俳優、アナウンサー",
                  "enthusiasm": "今まで応援してくださった方々のおかげでまた新たなステージを挑戦出来ることとても感謝しています。この全国の舞台で戦えることがとても嬉しく光栄に思います！\n見た目だけでなく、中身もステキな人たちばかりですが、最後まで諦めずチャレンジャー精神で精一杯自分なりに頑張っていきます！頑張りましょーま！で頑張っていくので、是非応援よろしくお願いします！また一緒に楽しい思い出作ろうね",
                  "circle": "法政大学ダンスサークル HSD(約350人在籍しており、代表をやってます)",
                  "magazine": "Fine boys",
                  "birthday": "1998/12/11",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
          {/* ミスターメンズリゼ賞/ミスターリゼウォーク賞 */}
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">ミスター<br className="is-hidden-desktop" />メンズリゼ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${5}`}
                entry={{
                  "id": 5,
                  "mysta": "",
                  "showroom": "mom20_mr_5",
                  "college": "獨協大学",
                  "name": "内藤 智也",
                  "furigana": "ないとう ともや",
                  "hometown": "埼玉県",
                  "grade": 3,
                  "department": "外国語学部",
                  "twitter": "mdc19_mr_5",
                  "instagram": "mdc19_mr_5",
                  "hobby": "一人旅、スポーツ観戦、筋トレ、カメラ",
                  "skill": "ギター、筋肉ルーレット",
                  "dream": "世界で一番幸せな家庭を築く！",
                  "enthusiasm": "「自分らしさ」を忘れず最後まで頑張ります！応援よろしくお願い致します！",
                  "circle": "",
                  "magazine": "ラグビーマガジン、サッカーマガジン",
                  "birthday": "1998/10/21",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">ミスター<br className="is-hidden-desktop" />リゼウォーク賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${6}`}
                entry={{
                  "id": 6,
                  "mysta": "",
                  "showroom": "mom20_mr_6",
                  "college": "桜美林大学",
                  "name": "一 光希",
                  "furigana": "はじめ こうき",
                  "hometown": "神奈川県",
                  "grade": 2,
                  "department": "リベラルアーツ学群",
                  "twitter": "mr5obirin_2019",
                  "instagram": "mr5obirin_2019",
                  "hobby": "tiktok YouTube 曲を聴く",
                  "skill": "ダンス　トリッキング",
                  "dream": "アーティスト",
                  "enthusiasm": "10/27の悔しさをバネにして、\n必ず頂点に立ちます。",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1999/10/24",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
          </div>
          {/* モデルプレス賞/MR COLLE賞 */}
          <div className="columns is-centered is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">モデルプレス賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  loadingImage={loadingImage}
                  href={`/profile/${14}`}
                  entry={{
                    "id": 14,
                    "mysta": "",
                    "showroom": "mom20_mr_14",
                    "twitter": "niino0311",
                    "college": "日本大学",
                    "name": "新納 侃",
                    "furigana": "にいの なおと",
                    "hometown": "千葉県",
                    "grade": 3,
                    "department": "文理学部",
                    "instagram": "niino0311",
                    "hobby": "読書,旅行",
                    "skill": "剣道",
                    "dream": "youtuber,俳優",
                    "enthusiasm": "グランプリ勝ち取りたいです。",
                    "circle": "",
                    "magazine": "MTLR",
                    "birthday": "",
                    "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    "finalist": true
                  }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">MR COLLE賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                  loadingImage={loadingImage}
                  href={`/profile/${19}`}
                  entry={{
                    "id": 19,
                    "mysta": "",
                    "showroom": "mom20_mr_19",
                    "college": "慶應義塾大学",
                    "name": "上江洲 クレイトン 恵一",
                    "furigana": "うえず くれいとん けいち",
                    "hometown": "ブラジル",
                    "grade": 1,
                    "department": "理工学部",
                    "twitter": "mr_keioygm192",
                    "instagram": "mr_keioygm192",
                    "hobby": "YouTubeを観ること / ダンス",
                    "skill": "ブレイクダンス",
                    "dream": "インフルエンサー",
                    "enthusiasm": "目標にしてた舞台です。頂点目指します。",
                    "circle": "Dance Crew es",
                    "magazine": "メンズノンノ",
                    "birthday": "1999/12/14",
                    "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    "finalist": true
                  }}
              />
            </div>
          </div>
          {/* フジテレビアナトレ賞/mysta賞/DHC賞 */}
          <div className="columns is-centered is-multiline is-mobile is-margin-bottom-6">
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title">フジテレビ<br className="is-hidden-tablet" />アナトレ賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${37}`}
                entry={{
                  "id": 37,
                  "mysta": "",
                  "showroom": "mom20_mr_37",
                  "college": "関西大学",
                  "name": "西川 和輝",
                  "furigana": "にしかわ かずき",
                  "hometown": "京都府",
                  "grade": 2,
                  "department": "法学部法学政治学科",
                  "twitter": "Mck_01kazuki",
                  "instagram": "kazuki_nishikawa_",
                  "hobby": "音楽を聴くこと",
                  "skill": "ラテアート",
                  "dream": "アナウンサー",
                  "enthusiasm": "関西大学のミスター、ミスコンテストを全国的に有名にできるように頑張ります。\nそして将来の夢に繋がるような活動にしたいです。",
                  "circle": "",
                  "magazine": "",
                  "birthday": "1999/11/13",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile is-no-uppercase as-section-title"><br className="is-hidden-tablet" />mysta賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${16}`}
                entry={{
                  "id": 16,
                  "mysta": "",
                  "showroom": "mom20_mr_16",
                  "college": "明治学院大学",
                  "name": "木立 瑠耶",
                  "furigana": "きだち るか",
                  "hometown": "神奈川県",
                  "grade": 3,
                  "department": "経済学部",
                  "twitter": "19mgumr_no5",
                  "instagram": "19mgumr_no5",
                  "hobby": "映画鑑賞、読書",
                  "skill": "バルーンアート、ハンドボール",
                  "dream": "かっこいい父親になること",
                  "enthusiasm": "明治学院の代表として精一杯頑張って皆の力を借りて優勝を目指します！！",
                  "circle": "",
                  "magazine": "メンズファッジ",
                  "birthday": "1998/12/14",
                  "images": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                  "finalist": true
                }}
              />
            </div>
            <div className={classNames('column is-3-desktop is-4-tablet is-6-mobile has-text-centered')}>
              <div className="is-margin-bottom-3">
                <h1 className="title is-size-5 is-size-6-mobile as-section-title"><br className="is-hidden-tablet" />DHC賞</h1>
                <h2 className="subtitle" />
              </div>
              <Card
                loadingImage={loadingImage}
                href={`/profile/${22}`}
                entry={{
                  "id": 22,
                  "mysta": "",
                  "showroom": "mom20_mr_22",
                  "college": "中央大学",
                  "name": "齋藤 健人",
                  "furigana": "さいとう けんと",
                  "hometown": "東京都",
                  "grade": 2,
                  "department": "理工学部",
                  "twitter": "mr3chuo_2019",
                  "instagram": "mr3chuo_2019",
                  "hobby": "歌うこと、ランニング、温泉巡り",
                  "skill": "歌うこと",
                  "dream": "多くの人を魅了する俳優",
                  "enthusiasm": "熱量だけは誰にも負けません。グランプリ目指して駆け抜けます。",
                  "circle": "テニスサークル",
                  "magazine": "Fine boys ,MEN’S NON-NO",
                  "birthday": "1998/11/03",
                  "images": [1],
                  "finalist": true
                }}
              />
            </div>
          </div>
        </div>
      </section>
      {/*

        Entries

      */}
      <section id="entries" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Finalist</h1>
            <h2 className="subtitle">ファイナリスト紹介</h2>
          </div>
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries list={entry.list as any} finalist>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.id}
                  >
                    <Card loadingImage={loadingImage} href={`/profile/${item.id}`} entry={item} />
                  </div>
                ))
              }
            </Entries>
          </div>
        </div>
      </section>
      {/*

        Information

      */}
      <section id="information" className="section">
        <div className="container" style={{ maxWidth: 740 }}>
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Information</h1>
            <h2 className="subtitle">イベント情報</h2>
          </div>
          <p className="has-text-centered is-size-5 has-text-weight-bold is-margin-bottom-0-5">
            MR OF MR CAMPUS CONTEST 2020 supported by メンズリゼ
          </p>
          <p className="has-text-centered is-size-5 has-text-weight-bold is-margin-bottom-3">
            MISS OF MISS CAMPUS QUEEN CONTEST 2020 supported by リゼクリニック
          </p>
          <Information />
        </div>
      </section>
      {/*

        Schedule

      */}
      <section id="schedule" className="section has-background-gradient">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Schedule</h1>
            <h2 className="subtitle is-margin-bottom-1-5">日本一のミスターキャンパスが決まるまで</h2>
            <p className="is-size-7 has-text-white has-text-centered">
              ※ スケジュールは予告なく変更されることがあります。
            </p>
          </div>
          <Timeline />
        </div>
      </section>
      {/*

        Photo

      */}
      <section id="photo" className="section">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">Photo</h1>
            <h2 className="subtitle">表彰式の様子</h2>
          </div>
          <Photos photos={photos} loadingImage={loadingImage} />
        </div>
      </section>

      {/*

        History

      */}
      <section id="history" className="section has-background-gradient-history">
        <div className="container">
          <div className="is-margin-bottom-3">
            <h1 className="title as-section-title">History</h1>
            <h2 className="subtitle">ミスオブミスの17年の歴史</h2>
          </div>
          <History json={history} />
        </div>
      </section>

      {/*

         News

      */}
      <section id="news" className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="is-margin-bottom-0">
                <h1 className="title as-section-title">News</h1>
                <h2 className="subtitle">お知らせ</h2>
              </div>
              <News json={news} />
            </div>
            <div className="column">
              <div className="is-margin-bottom-3">
                <h1 className="title as-section-title">FOLLOW</h1>
                <h2 className="subtitle">最新情報</h2>
                <Twitter />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Marks />
      <Footer src={logoColor} />
    </div>
  );
}

export default TopApp;
