import React, { useEffect } from 'react';
import { Link, useCurrentRoute, useView } from 'react-navi';
import Header from '@mom-web/components/src/header';
import Footer from '@mom-web/components/src/footer';
import { Helmet } from 'react-navi-helmet-async';

const logo = require('../assets/logo.svg');
const logoColor = require('../assets/logo_color.svg');
const datetime = require('../assets/datetime.svg');

function NewsApp() {
  let { title, data, url } = useCurrentRoute();
  let { connect, content, head } = useView()!;
  let { tags } = data;
  let { MDXComponent, readingTime } = content;
  console.log(data);
  return (
    <div id="NewsApp">
      <Helmet>
        <meta content={`${title} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
        <meta content={`${process.env.REACT_APP_URL}/${url.href}`} property="og:url" />
          <title>{`${title} | ${process.env.REACT_APP_WEBSITE_NAME}`}</title>
      </Helmet>
      <Header
        logo={logo}
        datetime={datetime}
        additional={
          <li className="is-hidden-mobile">
            <a href="https://missofmiss.jp/" target="_blank">
              <span className="icon is-small">
                <i className="fas fa-external-link-alt" aria-hidden="true" />
              </span>
              <span>MISS OF MISS</span>
            </a>
          </li>
        }
      />
      {/* Section */}
      <section className="section as-profile">
        <div className="container">
            <nav className="breadcrumb is-small  is-hidden-mobile" aria-label="breadcrumbs">
                <ul>
                    <li>
                        <Link href="/">Top</Link>
                    </li>
                    <li className="is-active">
                        <a href={url.href} aria-current="page">
                            {title}
                        </a>
                    </li>
                </ul>
            </nav>
          <div className="content">
              <h3>{title}</h3>
              <div className="tags">
                  {tags.map((tag: string) => (
                      <span className="tag" key={tag}>
                  {tag}
                </span>
                  ))}
              </div>
            <MDXComponent />
          </div>
        </div>
      </section>
      <Footer src={logoColor} />
    </div>
  );
}

export default NewsApp;
